import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {
  Button,
  IconEnum,
  Modal,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { PortalProvider, usePortals } from 'react-portal-hook';
import {
  colorBlack,
  colorWhite,
  tabletMax,
  groupBy,
  colorGreyLight,
  toCamelCase,
  colorPrimary,
} from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';
import { CallToAction } from '~/common/models';
export interface IVideoAllEpisodesProps {
  id?: number;
  rowTileCount?: number;
  tiles?: IVideoTile[];
  backgroundColor?: string;
  fontColor?: string;
  tileBackgroundColor?: string;
  tileFontColor?: string;
  moreEpisodesText?: string;
  seriesBackgroundColor?: string;
  header?: string;
  headerDisclaimer?: string;
  contentCTAs?: CallToAction[];
}
interface IVideoTile {
  header?: string;
  description?: string;
  disclaimer?: string;
  disclaimerFontColor?: string;
  videoSrc?: string;
  embeddedMedia?: string;
  seriesName?: string;
  idx?: number;
}
interface ModalProps {
  closeModal: () => void;
}
const isPlaying = (_this?: HTMLVideoElement | null) => {
  if (!_this) return null;
  return !_this.paused && _this.currentTime > 0 && !_this.ended;
};
const onVideoEnded = (_this?: HTMLVideoElement | null) => {
  if (_this) {
    _this.currentTime = 0;
  }
};
const onVideoPlayPause = (
  _this?: HTMLVideoElement | null,
  playCallback?: (_this?) => void,
  pauseCallback?: (_this?) => void
) => {
  if (_this) {
    if (isPlaying(_this)) {
      if (pauseCallback) {
        pauseCallback(_this);
        return;
      }
      _this.pause();
    } else {
      if (playCallback) {
        playCallback(_this);
        return;
      }
      _this.play();
    }
  }
};
const renderDisclaimer = (tile: IVideoTile) => {
  if (!tile.disclaimer) return <></>;
  return (
    <TooltipDisclaimer className={cn(styles.MediaDisclaimer)}>
      <p
        dangerouslySetInnerHTML={{
          __html: tile.disclaimer as string,
        }}
        style={{ color: `#${tile.disclaimerFontColor || colorWhite}` }}
      />
    </TooltipDisclaimer>
  );
};
const VideoTileHighlight: React.FC<IVideoTile> = (props) => {
  const { videoSrc, embeddedMedia } = props;
  const modalVideoRef = useRef<HTMLVideoElement>(null);
  const [modalPlaying, setModalPlaying] = useState(false);
  const handleHighlightedVideoClick = (e) => {
    onVideoPlayPause(
      e.target.tagName === 'VIDEO'
        ? e.currentTarget || e.target
        : modalVideoRef.current,
      (el) => {
        el.play();
        setModalPlaying(true);
      },
      (el) => {
        el.pause();
        setModalPlaying(false);
      }
    );
  };
  return (
    <>
      {embeddedMedia ? (
        <div
          dangerouslySetInnerHTML={{
            __html: embeddedMedia as string,
          }}
        />
      ) : videoSrc ? (
        <>
          <video
            ref={modalVideoRef}
            disablePictureInPicture
            onEnded={(e: any) => {
              onVideoEnded(e.target);
            }}
            onClick={handleHighlightedVideoClick}
            preload="auto"
            src={videoSrc}
          />
          {!modalPlaying && (
            <SvgIcon
              type={IconEnum.play}
              color="#323334"
              fill="#323334"
              size="100%"
              onClick={handleHighlightedVideoClick}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {(embeddedMedia || videoSrc) && renderDisclaimer(props)}
    </>
  );
};
const VideoTileModal: React.FC<ModalProps & IVideoTile> = ({
  closeModal,
  ...rest
}) => {
  return (
    <Modal
      closeModal={closeModal}
      modalContentClass={cn(styles.ModalContent, {
        [styles.ModalContentEmbed]: rest.embeddedMedia,
      })}
      closeButtonColor={'#fff'}
      lockScroll
    >
      <div
        className={cn(styles.MediaContainerModal, {
          [styles.MediaContainerModalEmbed]: rest.embeddedMedia,
        })}
      >
        <VideoTileHighlight {...rest} />
      </div>
    </Modal>
  );
};
const VideoAllEpisodesComponent = React.memo(
  ({
    id,
    tiles,
    rowTileCount,
    backgroundColor,
    fontColor,
    tileBackgroundColor,
    tileFontColor,
    seriesBackgroundColor,
    header,
    headerDisclaimer,
    contentCTAs,
  }: IVideoAllEpisodesProps) => {
    const _rowTileCount = rowTileCount || 4;
    const [playingIndex, setPlayingIndex] = useState(-1);
    const portalManager = usePortals();
    const parentRef = useRef<HTMLDivElement>(null);
    const _contentCTAs = contentCTAs?.filter(
      (x) => !(x.ctaHidden || (isMobile && x.ctaHideInMobile))
    );

    tiles?.forEach((o, idx) => (o.idx = idx));

    const seriesTiles =
      tiles && tiles.length > 0
        ? groupBy(tiles, (x) => x.seriesName || '')
        : [];

    const refs = Array(tiles?.length)
      .fill(0)
      .map((_) => React.createRef<HTMLVideoElement>());

    const [isMobile, setIsMobile] = useState(
      useMediaQuery(
        { query: `(max-width: ${tabletMax}px)` },
        undefined,
        (matches) => {
          setIsMobile(matches);
        }
      )
    );
    useRTETooltipPositionCorrector(isMobile);

    const renderTiles = (series: string, _seriesTiles: IVideoTile[]) => {
      if (
        !(tiles && tiles.length > 0 && _seriesTiles && _seriesTiles.length > 0)
      )
        return <></>;

      const handleVideoIconClick = (idx) => {
        refs.forEach((ref, i) => {
          onVideoPlayPause(
            ref.current,
            (e) => {
              if (i === idx) {
                setPlayingIndex(idx);
                e.play();
              }
            },
            (e) => {
              e.pause();
              if (i === idx) {
                setPlayingIndex(-1);
              }
            }
          );
        });
      };

      const handleModalView = (tile) => {
        portalManager.open(
          (portal) => <VideoTileModal closeModal={portal.close} {...tile} />,
          parentRef.current ? { appendTo: parentRef.current } : {}
        );
      };

      const handleViewMedia = (idx) => {
        const _tile = tiles[idx];
        if (playingIndex === idx) {
          onVideoPlayPause(refs[idx].current);
          setPlayingIndex(-1);
        }
        if (playingIndex !== -1) {
          if (playingIndex === idx) {
            onVideoPlayPause(refs[idx].current);
          } else {
            onVideoPlayPause(refs[playingIndex].current);
          }
          setPlayingIndex(-1);
        }
        if (!isMobile) {
          handleModalView(_tile);
        }
      };

      return _seriesTiles.map((tile) => {
        const idx = tile.idx as number;
        return (
          <div
            key={idx}
            className={cn(styles.VideoTile, {
              [styles.VideoTileHover]: !isMobile,
              [styles.VideoTileNoSeries]: series === '',
            })}
            style={{
              ...(!isMobile
                ? { flexBasis: `${100 / _rowTileCount}%` }
                : { flexBasis: '100%' }),
            }}
            onClick={() => handleViewMedia(idx)}
          >
            <div>
              <div
                className={cn(styles.MediaContainer, {
                  [styles.MediaContainerEmbed]: tile.embeddedMedia,
                })}
              >
                {tile.embeddedMedia ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tile.embeddedMedia as string,
                    }}
                  />
                ) : tile.videoSrc ? (
                  <>
                    <video
                      ref={refs[idx]}
                      disablePictureInPicture
                      onEnded={(e: any) => {
                        onVideoEnded(e.target || refs[idx].current);
                        setPlayingIndex(-1);
                      }}
                      preload="auto"
                      src={tile.videoSrc}
                    />
                    <div>
                      <SvgIcon
                        type={
                          playingIndex === idx
                            ? IconEnum.pauseCircle
                            : IconEnum.playCircle
                        }
                        color="#fff"
                        size={1.75}
                        onClick={(e) => {
                          handleVideoIconClick(idx);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(tile.embeddedMedia || tile.videoSrc) &&
                  renderDisclaimer(tile)}
              </div>
              {(tile.header || tile.description) && (
                <div
                  style={{
                    backgroundColor: `#${tileBackgroundColor || colorWhite}`,
                    color: `#${tileFontColor || colorBlack}`,
                  }}
                  className={styles.DetailsContainer}
                >
                  {tile.header && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tile.header as string,
                      }}
                    />
                  )}
                  {tile.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tile.description as string,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      });
    };

    return (
      <div
        ref={parentRef}
        key={id}
        id={`cid-${id}`}
        className={cn('full-device-width', styles.VideoAllEpisodesComponent)}
        style={{
          backgroundColor: `#${backgroundColor || colorWhite}`,
        }}
      >
        <div className={cn(styles.VideoAllEpisodesContainer)}>
          <MediaQuery minWidth={0}>
            <div className={cn(styles.VideoAllEpisodes)}>
              {header && (
                <div className={styles.VideoAllEpisodesHeader}>
                  <div
                    style={{
                      ...(fontColor
                        ? {
                            color: `#${fontColor}`,
                          }
                        : {}),
                    }}
                    dangerouslySetInnerHTML={{ __html: header as string }}
                  />
                  {headerDisclaimer && (
                    <TooltipDisclaimer
                      triggerClassName={styles.HeaderDisclaimerTrigger}
                      disclaimer={headerDisclaimer}
                    />
                  )}
                </div>
              )}
              {Object.keys(seriesTiles).map((series) => (
                <div
                  className={cn(styles.VideoSeries, {
                    [styles.VideoNoSeries]: series === '',
                  })}
                  key={series}
                >
                  {series !== '' && (
                    <h4
                      style={{
                        color: `#${fontColor || colorBlack}`,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: series as string,
                      }}
                    />
                  )}
                  <div
                    className={cn(styles.VideoTiles, {
                      [styles.VideoTilesNoSeries]: series === '',
                    })}
                    style={{
                      ...(series !== ''
                        ? {
                            backgroundColor: `#${
                              seriesBackgroundColor || colorGreyLight
                            }`,
                          }
                        : {}),
                    }}
                  >
                    {renderTiles(series, seriesTiles[series])}
                  </div>
                </div>
              ))}
              <>
                {_contentCTAs && _contentCTAs.length > 0 && (
                  <div className={styles.VideoAllEpisodesCTAs}>
                    {_contentCTAs?.map((cta, idx) => {
                      const buttonProps: any = {
                        type: toCamelCase(cta.ctaButtonType),
                        href: cta.ctaUrl?.url,
                        target: cta.ctaUrl?.target || '_self',
                      };
                      return (
                        <Button
                          key={idx}
                          className={cn(
                            styles.VideoAllEpisodesCTA,
                            styles[`VideoAllEpisodesCTA${buttonProps.type}`]
                          )}
                          buttonSize="medium"
                          {...buttonProps}
                        >
                          {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                            <SvgIcon
                              className={styles.VideoAllEpisodesCTAIcon}
                              type={cta.ctaIcon as keyof typeof IconEnum}
                              color={`#${colorPrimary}`}
                              size={1.2}
                              strokeWidth={2}
                            />
                          )}
                          <span
                            style={
                              buttonProps.type === 'text'
                                ? { color: `#${colorPrimary}` }
                                : {}
                            }
                          >
                            {cta.ctaUrl?.name || ''}
                          </span>
                        </Button>
                      );
                    })}
                  </div>
                )}
              </>
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
);
export const VideoAllEpisodes = React.memo((props: IVideoAllEpisodesProps) => {
  return (
    <PortalProvider>
      <VideoAllEpisodesComponent {...props} />
    </PortalProvider>
  );
});
